.header {
  &BrandImageSection {
    display: flex;
    justify-content: center;
    width: 100%;
    &Image {
      max-width: 280px;
      @media screen and (max-width: 768px) {
        max-width: 200px;
      }
      img {
        width: 100%;
      }
    }
  }
}
