// html,
// body {
//   padding: 0;
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
//     Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
// }

// a {
//   color: inherit;
//   text-decoration: none;
// }

// * {
//   box-sizing: border-box;
// }

@import './_base.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  overflow-y: auto;
}

body {
  font-family: Roboto, Arial, Helvetica, sans-serif !important;
  font-weight: 400;
  line-height: 1.7;
  color: #424242;
  box-sizing: border-box;
}
body.modal-open {
  overflow: visible !important;
  position: inherit;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin Italic'),
    url(https://fonts.gstatic.com/s/roboto/v19/KFOiCnqEu92Fr1Mu51QrEz0dL-vwnYh2eg.woff2)
      format('woff2');
  font-display: swap;
}

.tooltip .tooltip-inner {
  font-size: 1.2rem;
  background: $pl-white-bg-color;
  color: $pl-brand-logo-color-blue;
  border: 1px solid $pl-brand-logo-color-blue;
  border-radius: 1.2rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.container {
  padding: 0 !important;
  font-size: 1.2rem;
}

.pl-container {
  background-color: $pl-white-bg-color;
  padding-top: 1.2rem;
  margin: 0;
  padding-bottom: 5rem;
  //height: 100vh;
}

.pl-loading-spinner-section {
  display: flex;
  width: 100%;
  min-height: 10rem;
  justify-content: center;
  align-items: center;
}

.pl-postlo-app__spinner {
  min-height: 20rem;
  display: flex;
  align-items: center;
}

.responsive-image-container {
  width: 100%;
  position: relative;
  @media only screen and (min-width: 768px) {
    width: 72%;
  }

  > div {
    position: unset !important;
  }

  > span {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
  .pl-player-wrapper {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
  .player-wrapper {
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
