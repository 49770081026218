@import '../../styles/colors.scss';
.popular-search-links {
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  padding: 0.8rem;
  &__category {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.4rem;
    &__heading {
      margin-bottom: 0.4rem;
    }
    &__links {
      display: flex;
      flex-wrap: wrap;
      span {
        margin-right: 1.6rem;
        margin-bottom: 0.8rem;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        a {
          text-decoration: none;
        }
      }
    }
  }
}
