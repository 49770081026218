.pl-text {
    &__lineclamp {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    &__color-normal {
      color: #24292e;
    }
    &__color-danger {
      color: #de3535;
    }
    &__color-muted {
      color: #a0acb8;
    }
    &__color-pattern {
      color: #43a047;
    }
    &__color-primary {
      color: #0067c5;
    }
    &__color-success {
      color: #43a047;
    }
    &__color-white {
      color: #fffafa;
    }
    &__color-dark-blue {
      color: #0e2862;
    }
    &__color-blue {
      color: #1e88e5;
    }
    &__color-brand-orange {
      color: #ffa000;
    }
    &__color-label {
      color: #5f6368;
    }
  
    &__color-yellow {
      color: #ffa000;
    }
  
    &__size-extra-small {
      font-size: 0.8rem;
    }
    &__size-small {
      font-size: 1rem;
    }
    &__size-regular {
      font-size: 1.2rem;
    }
    &__size-medium {
      font-size: 1.4rem;
    }
    &__size-large {
      font-size: 1.6rem;
    }
    &__size-title {
      font-size: 2rem;
    }
    &__size-header {
      font-size: 2.4rem;
    }
  
    &__weight-thin {
      font-weight: 100;
    }
    &__weight-light {
      font-weight: 300;
    }
    &__weight-normal {
      font-weight: 400;
    }
    &__weight-medium {
      font-weight: 500;
    }
    &__weight-bold {
      font-weight: 700;
    }
    &__weight-black {
      font-weight: 900;
    }
  
    &__webkit-lineclamp-1 {
      -webkit-line-clamp: 1;
    }
    &__webkit-lineclamp-2 {
      -webkit-line-clamp: 2;
    }
    &__webkit-lineclamp-3 {
      -webkit-line-clamp: 3;
    }
    &__text-decoration-underline {
      text-decoration: underline;
    }
  }
  
  .pl-line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  