@import '../../styles/colors.scss';
.articles {
  margin-top: 0.8rem;
  @media screen and (max-width: 768px) {
    padding: 0.8rem;
  }
  &TitleSection {
    margin: 1.2rem 0.8rem;
    h1 {
      font-family: 'Play', sans-serif;
      margin: 0.8rem 0;
    }
    &Title {
      margin: 0.8rem 0;
    }
  }
  &Article {
    &Share {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0.8rem 0;
    }
    &Title {
      margin-bottom: 0.8rem;
    }
    &Published {
      margin-bottom: 1.2rem;
    }
    &Author {
      margin-top: 1.2rem;
    }
  }
  &TextBullet {
    display: flex;
    align-items: center;
    margin-left: 0.8rem;
    &Icon {
      margin-right: 1.2rem;
    }
    span {
      margin-right: 0.4rem;
    }
  }
  &NumberBullet {
    display: flex;
    align-items: center;
    &Number {
      margin-right: 0.8rem;
    }
  }
}
