$pl-theme-blue-color: #3071a9;

// Blue
//$pl-primary-blue-color: #039be5;
$pl-primary-blue-color: #2196f3;

$pl-dark-blue-color: #006db3;
$pl-text-blue-color: #1e88e5;
$pl-light-blue-color: #e1f5fe;

// Blue-Grey
$pl-extra-dark-blue-grey-color: #455a64;
$pl-dark-blue-grey-color: #546e7a;
$pl-normal-blue-grey-color: #cfd8dc;
$pl-light-blue-grey-color: #eceff1;

// Orange

$pl-primary-orange-color: #ffa000;
$pl-dark-orange-color: #c67100;

// Grey
$pl-extra-dark-grey-color: #9e9e9e;
$pl-dark-grey-color: #bdbdbd;
$pl-normal-grey-color: #eeeeee;
$pl-light-grey-color: #e0e0e0;

$pl-muted-text-color: #a0acb8;

// Green

$pl-regular-green-color: #4caf50;
$pl-light-green-color: #66bb6a;

$pl-light-cyan-color: #e0f7fa;
$pl-medium-cyan-color: #b2ebf2;
//$pl-dark-blue-color: #0277bd;
//$pl-light-blue-color: #4285f4;

$pl-label-color: #5f6368;
$pl-post-black-color: #0c0c0c;
$pl-text-black-color: #24292e;
$pl-text-white-color: #fffafa;

$pl-dark-red-color: #e53935;

$pl-normal-red-color: #db4437;
$pl-light-red-color: #e57373;

//$pl-dark-orange-color: #f57c00;

$pl-white-bg-color: #fafafa;

$pl-navlinks-background-color: #d32f2f;

// Google Colors

$pl-google-blue: #4285f4;
$pl-google-red: #db4437;
$pl-google-orange: #f4b400;
$pl-google-green: #0f9d58;

// Digital Colors

$pl-digital-blue-link-color: #0067c5;
$pl-digital-blue-button-color: #0071bc;

// Brand color
$pl-brand-color-blue: #2196f3;
$pl-brand-logo-color-blue: #0e2862;
$pl-brand-logo-color-orange: #ffa000;
